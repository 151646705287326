<template>
  <span class="load-image">
  	<img :src="src" :width="width" :height="height" :title="title" :class="imgClass" :alt = "alt" v-if="status === 'loaded'"
  		@load="status='loaded'" @error="status='failed'"
  	/>
    <span :class="spinnerClass" :style="'width:' + width + ';height:' + height + ';'" v-else-if="status === 'loading'"></span>
    <div :width="width" :height="height" :title="title" :class="imgClass" v-else-if="status === 'failed'">{{ alt }}</div>
  </span>
</template>

<script>
const Status = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed'
}

export default {
  data() {
    return {
        status: null,
        img: null
    }
  },
  props: {
	  src: String,
	  width: Number,
	  height: Number,
	  imgClass: String,
	  title: String,
	  alt: String
  },
  computed: {
	spinnerClass: function() {
  		return 'fas fa-cog fa-spin';
  	}
  },
  created() {
    if (this.src) {
      this.status = Status.LOADING
      this.createLoader()
    } else {
      this.status = Status.PENDING
    }
  },
  updated() {
    const receivedSrc = this.src;
    if (this.status === Status.LOADING && !this.img) {
      this.createLoader()
    } else if (this.src !== receivedSrc) {
      this.src = receivedSrc
      this.createLoader()
    }
  },
  watch: {
    src(value) {
      this.status = value ? Status.LOADING : Status.PENDING
    }
  },
  methods: {
    createLoader() {
      this.destroyLoader()
      this.img = new Image()
      this.img.onload = this.handleLoad
      this.img.onerror = this.handleError
      this.img.src = this.src
    },
    destroyLoader() {
      if (this.img) {
        this.img.onload = null
        this.img.onerror = null
        this.img = null
      }
    },
    handleLoad() {
      this.destroyLoader()
      this.status = Status.LOADED
      this.$emit('onLoad')
    },
    handleError(error) {
      this.destroyLoader()
      this.status = Status.FAILED
      this.$emit('onError', error)
    }
  }
}
</script>
<style scoped>
	@import "../../assets/stylesheets/load_image.css"
</style>