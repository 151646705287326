import Chart from 'chart.js/auto';

export function block_screen() {
	// Para evitar perdida de transparencia si se acumulan componentes .block-window
	unblock_screen();
	//$('body').append("<div class='block-window'><i class=\"fa fa-cog fa-pulse fa-fw fa-5x\"></i></div>");
	//$('body').append("<div class='block-window'></div>");
	$('body').addClass("loading");
};

export function unblock_screen() {
	//$(".block-window").remove();
	$('body').removeClass("loading");
};

export function setIntervalTurbolinks(myFunction, millis) {
	var myVar = setInterval(myFunction, millis);

/*	
	$(document).on('turbolinks:before-cache turbolinks:before-render', function() {
	    clearTimeout(myVar);
	});
*/	
	$(window).on('beforeunload', function() {
	    clearTimeout(myVar);
	});

	return myVar;
};

export function getIconByLeagueRule(league_rule_id) {
	if (league_rule_id == 8 || league_rule_id == 14) {
		return 'fa fa-trophy ';
	}
	if (league_rule_id == 12) {
		return 'fa fa-award ';
	}
	if (league_rule_id == 13) {
		return 'fa fa-arrow-alt-circle-down ';
	}
	if (league_rule_id >= 4 && league_rule_id <= 7) {
		return 'fa fa-hand-point-right ';
	}
	if (league_rule_id == 10) {
		return 'fas fa-futbol ';
	}
	return '';
};

export function clearChart(canvasId) {
	let previousChart = Chart.getChart(canvasId);
	if (previousChart != undefined) {
		previousChart.destroy();
	}
};

export function createChart(canvasId, chartType, labels, datasets, options) {
	const ctx = document.getElementById(canvasId);
	let chart = new Chart(ctx, {
		type: chartType,
		data: {
			labels: labels,
			datasets: datasets
	    },
	    options: options
	});
};

export function normalizeString(s) {
	return s.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').trim();
}

export function getKey(entity, id) {
	return entity + "_" + id;
}

export function existsInLocal(key) {

	if(localStorage.getItem(key)) {
		var serialized = loadJSONFromLocal(key);
		if(!serialized.timestamp || !serialized.ttl || (!serialized.data && serialized.data !== null ) || new Date() - new Date(serialized.timestamp) > serialized.ttl) {
			localStorage.removeItem('key');
			return false;
		} else {
			return true;
		}
		
	} else {
		return false;
	}
}

export function loadJSONFromLocal(key) {
    return JSON.parse(localStorage.getItem(key));
};
export function saveJSONToLocal(key, jsonObject, ttl) {
	var serialized = {
		data: jsonObject,
		timestamp: new Date(),
		ttl: ttl
	}	
    localStorage.setItem(key, JSON.stringify(serialized));
};

// TODO Hacer esto mejor...
export function loadOptionsFromLocal() {
	var key = "options";
	if(localStorage.getItem(key)) {
	    return JSON.parse(localStorage.getItem(key));
	} else {
		return {};
	}
};
export function saveOptionsToLocal(serialized) {
	var key = "options";
    localStorage.setItem(key, JSON.stringify(serialized));
};

/* Funciones para copiar al portapapeles */
export function copyToClipboard(textToCopy, onSuccess, onError) {

	if (!navigator.clipboard){
		// use old commandExec() way
		function listener(e) {
			e.clipboardData.setData("text/html", textToCopy);
			e.clipboardData.setData("text/plain", textToCopy);
			e.preventDefault();
		}
		document.addEventListener("copy", listener);
		if(document.execCommand("copy")) {
			onSuccess();
		} else {
			onError();
		}
		document.removeEventListener("copy", listener);
	} else{
		// use clipboard API
		navigator.clipboard.writeText(textToCopy).then(
			() => {
				onSuccess();
			})
		  .catch((error) => {
				copyToClipboardSafari(textToCopy);
				onError();
			});
	}
}
function copyToClipboardSafari(message) {
	const clipboardItem = new ClipboardItem({
		'text/plain': someAsyncMethod().then((result) => {

			/**
			 * We have to return an empty string to the clipboard if something bad happens, otherwise the
			 * return type for the ClipBoardItem is incorrect.
			 */
			if (!result) {
				return new Promise(async (resolve) => {
					resolve(new Blob[``]())
				})
			}

			return new Promise(async (resolve) => {
				resolve(new Blob([message]))
			});
		}),
	})
	// Now, we can write to the clipboard in Safari
	navigator.clipboard.write([clipboardItem]);
}


