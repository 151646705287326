<script setup>
import ActionManager from "../packs/modules/ActionManager";
import ResultBetAction from "../packs/modules/actions/ResultBetAction";
//import functions from 'packs/functions.js';
var I18n = window.I18n;
</script>
<template>
	<div :title="title" v-if="bet.allow_show_bets">
		<select v-model="bet.local_goals_edit" :disabled="!bet.allow_edit_bets" @change="marcadorChange($event)" :class="betClass(bet.allow_edit_bets, bet.local_goals, bet.modified_after_start)">
			<option value=""></option>
			<!-- Este bucle ayuda a que la opción correcta salga bien marcada al darle hacia atrás en el navegador -->
			<template v-for="n in 10">
				<option :value="n-1" selected="selected" v-if="bet.local_goals == n-1">{{ n - 1 }}</option>
				<option :value="n-1" v-else>{{ n - 1 }}</option>
			</template>
		</select>
		<select v-model="bet.visiting_goals_edit" :disabled="!bet.allow_edit_bets" @change="marcadorChange($event)" :class="betClass(bet.allow_edit_bets, bet.visiting_goals, bet.modified_after_start)">
			<option value=""></option>
			<!-- Este bucle ayuda a que la opción correcta salga bien marcada al darle hacia atrás en el navegador -->
			<template v-for="n in 10">
				<option :value="n-1" selected="selected" v-if="bet.visiting_goals == n-1">{{ n - 1 }}</option>
				<option :value="n-1" v-else>{{ n - 1 }}</option>
			</template>
		</select>
	</div>
	<div class="marcador" v-else :title="I18n.t('match_not_started')">
		<span :class="{'fa fa-clock': true, 'success': bet.result_established, 'danger': !bet.result_established }"></span>
	</div>
</template>
<script>

export default {
  name: 'bet-form',
  props: {
  	bet: Object,
	mode: String,
	actionManager: ActionManager
  },
  computed: {
  	title: function() {
  		if(this.bet.modified_after_start) {
		  	return 'Modified ' + new Date(this.bet.updated_at).toISOString();
  		} else {
  			return (this.bet.local_goals != null ? this.bet.local_goals : "?") + " - " + (this.bet.visiting_goals != null ? this.bet.visiting_goals : "?");
  		}
  	}
  },
  methods: {
  	marcadorChange: function(event) {

		var bet = this.bet;

		var action = new ResultBetAction(this.bet, bet.local_goals, bet.visiting_goals, bet.local_goals_edit, bet.visiting_goals_edit,
			I18n.locale, this.mode);

		this.actionManager.execute(action);
		
	},
	betClass: function(editable, goals, modified){
		if(editable) {
			if(goals != null && goals !== "") {
				return "filled";
			} else {
				return "pending";
			}
		} else {
			if(modified) {
				return "bet-modified"
			} else {
				return "";
			}
		}
	}
  }
}
</script>

<style scoped>
	@import "../../assets/stylesheets/bet_form.css"
</style>
