import Action from '../Action';
import ApiService from '../ApiService';
	
export default class ResultBetAction extends Action {
    constructor(bet, previousLocalGoals, previousVisitingGoals, localGoals, visitingGoals, locale, mode) {
		super();
		this.bet = bet;
		this.previousLocalGoals = previousLocalGoals;
		this.previousVisitingGoals = previousVisitingGoals;
		this.localGoals = localGoals;
		this.visitingGoals = visitingGoals;
		this.locale = locale;
		this.mode = mode;
    }
    do() {
		this.bet.local_goals = this.localGoals;
		this.bet.visiting_goals = this.visitingGoals;
		this.bet.local_goals_edit = this.localGoals;
		this.bet.visiting_goals_edit = this.visitingGoals;
		
		return this.execute();
    }
    undo() {
		this.bet.local_goals = this.previousLocalGoals;
		this.bet.visiting_goals = this.previousVisitingGoals;
		this.bet.local_goals_edit = this.previousLocalGoals;
		this.bet.visiting_goals_edit = this.previousVisitingGoals;
		
		return this.execute();
    }
	execute() {
		var bet = this.bet;
        var localTeam = bet.match.local_team ? bet.match.local_team.team_name : " --- ";
        var localGoals = (bet.local_goals !== "" && bet.local_goals !== null ? bet.local_goals : "?");
        var visitingTeam = bet.match.visiting_team ? bet.match.visiting_team.team_name : " --- ";
        var visitingGoals = (bet.visiting_goals !== "" && bet.visiting_goals !== null ? bet.visiting_goals : "?")

  		// crear timestamp para identificacion
		var id = bet.id + "_" + Date.now();
  		// crear mensaje
		var message = localTeam + " " + localGoals + " - " + visitingGoals + " " + visitingTeam;

  		// hacer push a notification_list para que salga el aviso
		window.notifications.push(id, message);

		ApiService.updateResultBet(bet.id, bet.local_goals, bet.visiting_goals, this.mode, 
			(response) => {
		  		// al recibir respuesta, hacer update de la notificación
		  		window.notifications.update(id, response.status);
		  		// y actualizar apuestas
		  		ApiService.getAlerts((response2) => {
		  			window.alerts.update(response2.data);
		  		}, (error) => {}, () => {});
	  		},
			(error) => {
		  		// al recibir respuesta, hacer update
			  	window.notifications.update(id, error.response.status);
			},
			() => {
			}
		);
		
		return true;

	}
}
