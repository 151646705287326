import Action from './Action';

export default class ActionManager {
	constructor() {
        this.undoList = [];
        this.redoList = [];
	}

    execute(action) {
        var success = action.do();
		if (success) {
        	this.undoList.push(action);
        	this.redoList = [];
		}
    }
    undo() {
        var action = this.undoList.pop(); // remove
        if(action) {
            var success = action.undo();
			if (success) {
	            this.redoList.push(action);
			}
        }
    }
    redo() {
        var action = this.redoList.pop(); // remove
        if(action) {
            var success = action.do();
			if (success) {
	            this.undoList.push(action);
	        }
        }
    }
    undoDisabled() {
        return this.undoList.length == 0;
    }
    redoDisabled() {
        return this.redoList.length == 0;
    }
}
