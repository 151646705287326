<script setup>
import * as Vue from 'vue';
import { drawCircleWithColors } from '../packs/drawing';
</script>
<template>
    <load-image :class="imageSize"
	    :src="logoSrc" :width="width" :height="height" :title="teamName" :img-class="logoClass" alt = "[X]"
		@onError="onError"
		v-if="usingImg"
    >
	</load-image>
	<span :class="logoClass + ' load-image'" :title="teamName" v-else>
		<canvas :id="'canvas' + teamName + width" :width="width" :height="height"></canvas>
	</span>
</template>
<script>
	import LoadImage from './load_image.vue';
	export default {
	  name: 'logo',
	  data() {
		return {
			usingImg: true
		}
	  },
	  props: {
		team: Object,
	  	small: Boolean
	  },
	  computed: {
		logoSrc: function() {
			return this.team ? this.team.flag_url : "";
		},
	  	teamName: function() {
			return this.team ? this.team.team_name : "";
		},
	  	teamColors: function() {
			return this.team ? this.team.colors_array : [];
		},
	  	isFlag: function() {
			return this.team ? this.team.is_flag : false;
		},
	  	width: function() {
	  		return(this.isFlag ? (this.small ? 30 : 100) : (this.small ? 35 : 120));
	  	},
	  	height: function() {
	  		return(this.isFlag ? (this.small ? 30 : 100) : (this.small ? 30 : 120));
	  	},
	  	imageSize: function() {
	  		return(this.small ? 'small-image' : 'large-image');
	  	},
	  	logoClass: function() {
	  		return this.isFlag ? "team-flag" : "team-logo";
	  	},
	  	spinnerClass: function() {
	  		return 'fas fa-cog fa-spin' + (this.small ? '' : ' fa-5x');
	  	}
	  },
	  methods: {
		onError(e) {
			if(this.teamColors && this.teamColors.length > 0) {
				this.usingImg = false;
				Vue.nextTick(() => {
					drawCircleWithColors("canvas" + this.teamName +  this.width, this.width / 2, this.height / 2, Math.min(this.width, this.height) / 2 - 1, this.teamColors);
				});
			}
		}
	  },
	  mounted() {
	  },
	components: {
		"load-image": LoadImage
	}
}
</script>

<style scoped>
	@import "../../assets/stylesheets/logo.css"
</style>
  
