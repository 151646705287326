<script setup>
import Pagination from './components/pagination.vue';
import MatchCarousel from './components/match_carousel.vue';
import LeagueSummary from './components/league_summary.vue';
import Slideshow from './components/slideshow.vue';
import ApiService from "./packs/modules/ApiService";
import { setIntervalTurbolinks, loadOptionsFromLocal, saveOptionsToLocal } from './packs/functions';
var I18n = window.I18n;
</script>

<template>
	<div class="container-fluid main-page-container">
		<div class="match-carousel-container">
			<match-carousel 
				v-bind:matches="matches"
				v-bind:loading="loadingMatches"
				v-bind:is-active="isActive">
			</match-carousel>
		</div>
		<div class="index-layout v-cloak--hidden">
			<div>
				<div>
					<!-- 
					<div class="porra-card margin-top-20" v-if="alerts.length > 0">
						<div class="porra-well">
							<h4><span class="fas fa-tasks"></span> {{ I18n.t('messages') }} </h4>
						</div>
						<div>
							<div v-for="alert in alerts">
							    <div class="league">
					            	<a :title="I18n.t('my_bets')" class="league-content no-deco" :href="'/' + I18n.locale + '/result_bets/new?league_id=' + alert.league_id + '&matchday=' + alert.matchday">
								      	<div class="index-message">
											<span :class="{'link-fa link-shadow': true, 'far fa-edit warning': alert.alert_type == 1, 'fa fa-hand-point-right danger': alert.alert_type == 2}"></span> {{ alert.message }}
										</div>
								      	<div class="league-competition">
											{{ alert.league_name }}
										</div>
									</a>
							    </div>
							</div>
						</div>
					</div>
 					-->
 					<div class="porra-card margin-top-30 index-my-leagues">
						<div class="porra-well">
							<h4><span class="fa fa-users"></span> {{ I18n.t('my_leagues') }}</h4>
							<span>
								<span class="porra-card-button">
									<a :href="'/' + I18n.locale + '/leagues/search'" class="no-deco" :title="I18n.t('search_leagues')">
										<span class="fas fa-lg fa-search link-fa transparent"></span>
									</a>
								</span>
								<span class="porra-card-button">
									<a :href="'/' + I18n.locale + '/leagues/new'" class="no-deco" :title="I18n.t('create_league')">
										<span class="fas fa-lg fa-plus-circle link-fa transparent"></span>
									</a>
								</span>
							</span>
						</div>
						<div class="v-cloak--hidden">
							<div v-if="isUserSignedIn">
								<div v-if="loadingLeagues">
									<div class="text-center pad-10">
										<i class="fa fa-spinner fa-pulse fa-fw"></i> {{ I18n.t('loading') }}
								    </div>
								</div>
								<div v-else-if="leagues && leagues.length > 0">
								    <div class="league w-100" v-for="league in leagues" :key="league.id">
										<a :href="'/' + I18n.locale + '/leagues/' + league.id" class="league-content no-deco">
											<div class="league-name">
												{{ league.name }} 
											</div>
											<div class="league-competition">
												<!--
												<img :src="league.competition.competition_type.emblem" width="24" height="24">
												-->
												{{ league.competition.name }}
											</div>
										</a>
										<span class="league-icons">
							        		<a :href="'/' + I18n.locale + '/result_bets/new?league_id=' + league.id + '&user_id=' + userId"
							        			:title="I18n.t('my_bets')">
												<span class="league-icon link-fa far fa-edit"></span>
							        		</a>
							        		<a :href="'/' + I18n.locale + '/leagues/' + league.id"
							        			:title="I18n.t('league_table')">
												<span class="league-icon link-fa far fa-list-alt"></span>
							        		</a>
										</span>
										<!-- 
										<span v-show="isBigScreen && alerts.filter(a => a.league_id == league.id && a.alert_type == 1).length > 0">
											<span class="warning">*</span>
										</span>
										 -->
								    </div>
									<pagination v-if="maxPage > 1"
										v-bind:initial-page="page" 
										v-bind:min-page="1" 
										v-bind:max-page="maxPage" 
										pagination-class="porra-pagination-light" 
										@page-change="pageChange">
									</pagination>
								</div>
								<div class="message" v-else>
									<em>{{ I18n.t('no_leagues') }}</em>
								</div>
								<!--
									<div class="porra-pagination-light">< % = will_paginate @leagues, :container => false % ></div>
								-->
							</div>
							<div class="message" v-else>
								{{ I18n.t('welcome_message') }} <a :href="'/' + I18n.locale + '/users/sign_in'" class="link">{{ I18n.t('sign_in_with_your_account') }}</a>
								{{ I18n.t('or') }} <a :href="'/' + I18n.locale + '/users/sign_up'" class="link">{{ I18n.t('create_one') }}</a>
							</div>
						</div>
					</div>
					<div class="pad-5 index-welcome" v-if="isUserSignedIn">
						<div :title="showOldLeagues ? I18n.t('hide_old_leagues') : I18n.t('show_old_leagues')" v-if="existOldLeagues">
							<span class="main-page-link" @click="toggleShowOldLeagues"><span :class="{'far': true, 'fa-eye-slash': showOldLeagues, 'fa-eye': !showOldLeagues}"></span> {{ showOldLeagues ? I18n.t('hide_old_leagues') : I18n.t('show_old_leagues')}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="index-welcome" style="height: calc(100vh - 180px);">
				<template v-if="isUserSignedIn">
 					<div class="transparent-card margin-top-30" v-if="league">
						<slideshow :update-page="setLeague">
							<template v-slot:title>
								{{ league ? league.name: "" }}
							</template>
							<template v-for="leagueItem in leagues" :key="leagueItem.id">
								<league-summary
									:league-id="leagueItem.id"
									:competition-id="leagueItem.competition_id"
									:title="leagueItem.name"
									:user-id="userId"/>
							</template>
						</slideshow>
					</div>
				</template>
				<div class="message big" v-else>
					<div>
						{{ I18n.t('catching_phrase') }}
					</div>
					<div>
						{{ I18n.t('catching_subphrase') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  props: {
	    // alerts: Array,
	    userId: Number,
	    isUserSignedIn: Boolean
  },
  data() {
    return {
	    loadingLeagues: true,
	    loadingMatches: true,
	    allLeagues: [],
	    matches: [{}, {}, {}, {}, {}, {}], // Sale el carrusel con los glowing placeholder
	    activeLeagueIndex: 0,
		slideType: "slideshow-slide",
	    page: 1,
	    pageSize: 5,
	    showOldLeagues: false
    }
  },
  components: {
  	'match-carousel' : MatchCarousel,
  	'league-summary' : LeagueSummary,
  	'pagination' : Pagination,
  	'slideshow' : Slideshow
  },
  computed: {
	validLeagues() {
		return this.allLeagues.filter(x => this.showOldLeagues || (x.competition && !x.competition.finished));
	},
	leagues() {
		return this.validLeagues.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
	},
	league() {
		if(this.leagues && this.leagues.length > 0 && this.activeLeagueIndex >= 0 && this.activeLeagueIndex < this.leagues.length) {
			return this.leagues[this.activeLeagueIndex];
		} else{
			return null;
		}
	},
	maxPage() {
		return Math.ceil(this.validLeagues.length / this.pageSize);
	},
	existOldLeagues() {
		return this.allLeagues.filter(x => x.competition.finished).length > 0;
	},
	isBigScreen() {
		return window.matchMedia('(min-width: 992px)');
	}
  },
  methods: {
  	isActive() {
  		return false;
  	},
  	readLeagues() {
  		this.loadingLeagues = true;
	  	//var leagueId = this.leagueId;
		ApiService.getUserLeagues(null, 
			(response) => {
		  		this.allLeagues = response.data;
	  		},
			(error) => {
			},
			() => {
		  		this.loadingLeagues = false;
			}
		);
  	},
  	readMatches() {
  		this.loadingMatches = true;
	  	//var leagueId = this.leagueId;
		ApiService.nextMatches(
			(response) => {
		  		this.matches = response.data;
	  		},
			(error) => {
			},
			() => {
		  		this.loadingMatches = false;
			}
		);
  	},
	// Thumbnail image controls
	setLeague(n) {
		if(n != this.activeLeagueIndex) {
			this.activeLeagueIndex = n;
		}
	},
	pageChange(newPage) {
	    this.slideType = "";
		this.page = newPage;
		this.setLeague(0);
	},
	toggleShowOldLeagues() {
		this.showOldLeagues = !this.showOldLeagues
		this.pageChange(1);
		
		// Update options to local cache
		var options = loadOptionsFromLocal();
		options.showOldLeagues = this.showOldLeagues;
		saveOptionsToLocal(options); // Infinite TTL
	
	}
  },
  mounted() {
	// Update next matches
	this.readLeagues();
  	this.readMatches();
	setIntervalTurbolinks(this.readMatches, 60000);

	// Load options from local cache
	var options = loadOptionsFromLocal("options");
	this.showOldLeagues = options.showOldLeagues != null && options.showOldLeagues;
  }
};
  
</script>

<style scoped>
	@import "../assets/stylesheets/index_leagues.css"
</style>
