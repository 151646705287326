<template>
	<div class="slideshow-container">
		<!-- The dots/circles -->
 		<div style="text-align:center" v-if="numberOfElements > 0">
		  <span :class="{'dot': true, 'dot-active': index == i}" v-for="(page, i) in numberOfElements" :key="i" @click="setPage(i)"></span>
		</div>
		<!-- Next and previous buttons -->
		<!--
		<span class="prev" @click="plusPage(-1)" v-if="numberOfElements > 0"><span class="fas fa-chevron-left"></span></span>
		<span class="next" @click="plusPage(1)" v-if="numberOfElements > 0"><span class="fas fa-chevron-right"></span></span>
		-->
		<div class="w-100 flex justify-content-between align-items-center">
			<span style="position: relative;">
				<span class="prev" @click="plusPage(-1)" v-if="numberOfElements > 0"><span class="fas fa-chevron-left"></span></span>
			</span>
			<h3 class="text-center">
				<slot name="title"></slot>
			</h3>
			<span style="position: relative;">
				<span class="next" @click="plusPage(1)" v-if="numberOfElements > 0"><span class="fas fa-chevron-right"></span></span>
			</span>
		</div>
	</div>
	<div id="parent">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'slideshow',
	  	data: function() {
	  		return {
	  			numberOfElements: 0,
  				index: 0
	  		}
	  	},
	  	props: {
			updatePage: Function
	  	},
		computed: {
		},
		methods: {
			// Next/previous controls
		  	plusPage(n) {
				this.changePage((this.index + n + this.numberOfElements) % this.numberOfElements, n >= 0);
			},
			// Thumbnail image controls
			setPage(n) {
				if(n != this.activeLeagueIndex) {
					this.changePage(n, n >= this.index);
				}
			},
			changePage(n, moveRight) {
				var slideType = moveRight ? "slideshow-slide" : "slideshow-slide-left";
				if(this.numberOfElements > 1) {
					this.index = n;
					this.updatePage(this.index);
				}
				
				this.updateVisibility();
				
				// Esta ñapa horrible es para que las slides se muevan hacia derecha o izquierda de manera coherente a la flecha que marques
		  		for(var i = 0; i < this.numberOfElements; i++) {
		  			document.getElementById("parent").children[i].className =
			  			document.getElementById("parent").children[i].className.replace("slideshow-slide-left", "").replace("slideshow-slide", "").trim();
		  			if(this.index == i) {
			  			document.getElementById("parent").children[i].className += " " + slideType;
		  			}
		  		}
			},
			updateVisibility() {
		  		this.numberOfElements = document.getElementById("parent").childElementCount;
		  		for(var i = 0; i < this.numberOfElements; i++) {
		  			document.getElementById("parent").children[i].hidden = this.index != i;	
		  		}
			}
		},
	  	mounted() {
			this.updateVisibility();
	  	},
	  	updated() {
			this.updateVisibility();
	  	},
		components: {
		}
	}
</script>

<style scoped>
	@import "../../assets/stylesheets/slideshow.css"
</style>
