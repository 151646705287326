<script setup>
import Bet from './bet.vue';
import UsersLeagueTable from './users_league_table.vue';
import ApiService from "../packs/modules/ApiService";
import ActionManager from '../packs/modules/ActionManager';
import {clearChart, createChart} from '../packs/functions';
var I18n = window.I18n;
</script>

<template>
	<div>
		<!--
		<div class="flex justify-content-center">
			<h3 class="text-center">
				<span class="v-cloak--hidden">
					{{ title }}
				</span>
			</h3>
		</div>
		-->
		<div class="league-summary">
			<div class="">
				<div class="porra-subheader">
					<h4 class="text-center"><span class="fas fa-satellite-dish"></span> {{ I18n.t('matches') }}</h4>
				</div>
				<div class="main-page-card">
					<div class="league-table-container">
						<bet v-for="bet in lastBets" :key="bet.id"
							v-bind:bet="bet"
							mode=""
							v-bind:action-manager="actionManager"
							class="porra-card">
						</bet>
					</div>
				</div>
				<a :href="'/' + I18n.locale + '/result_bets/new?league_id=' + leagueId + '&user_id=' + userId" :title="I18n.t('my_bets')" class="pad-5 main-page-link">
					<span class="far fa-edit"></span> {{ I18n.t('my_bets') }}
				</a>
			</div>
			<div class="">
				<div class="porra-subheader">
					<h4 class="text-center"><span class="fa fa-medal"></span> {{ I18n.t('score') }}</h4>
				</div>
				<div class="porra-card main-page-card pad-5">
					<div class="league-table-container">
						<div>
							<users-league-table ref="leagueTables"
								v-bind:league-id="leagueId"
						  		v-bind:matchday="null"
					  			v-bind:user-id="userId"
					  			v-bind:max-rows-to-show="5"
					  			v-bind:compact="true">
				  			</users-league-table>
						</div>
					</div>
				</div>
				<a :href="'/' + I18n.locale + '/leagues/' + leagueId" :title="I18n.t('full_league_table')" class="pad-5 main-page-link">
					<span class="far fa-list-alt"></span> {{ I18n.t('full_league_table') }}
				</a>
			</div>
			<div v-show="isVisibleNextRoundBets && nextRound != null" class="graph-parent margin-top-10">
				<h4 class="text-center"><span class="fa fa-hand-point-right"></span> {{ nextRound ? nextRound.name : "" }}</h4>
				<div class="main-page-card pad-5">
					<canvas :id="'nextRoundChart' + leagueId"></canvas>
				</div>
			</div>
			<div v-show="isVisibleWinnerRoundBets" class="graph-parent margin-top-10">
				<h4 class="text-center"><span class="fa fa-trophy"></span> {{ I18n.t('winner') }}</h4>
				<div class="main-page-card pad-5">
					<canvas :id="'winnersChart' + leagueId"></canvas>
				</div>
			</div>
			<div v-show="isVisibleScorerRoundBets" class="graph-parent margin-top-10">
				<h4 class="text-center"><span class="fa fa-futbol"></span> {{ I18n.t('top_scorers') }}</h4>
				<div class="main-page-card pad-5">
					<canvas :id="'scorersChart' + leagueId"></canvas>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
export default {
	data: function() {
		return {
			lastBets: [],
			nextRound: null,
			nextRoundBets: [],
			winnerRoundBets: [],
			scorerPersonBets: [],
			actionManager: new ActionManager()
		}
	},
	mounted() {
		this.update();
		this.updateChart();
	},
	props: {
		leagueId: Number,
		competitionId: Number,
		title: String,
		userId: Number
	},
	computed: {
		isVisibleNextRoundBets() {
			// Todas las apuestas son visibles y no editables
			let visibleRules = this.nextRoundBets.filter(x => x.allow_show_bets && !x.allow_edit_bets && x.team_id != null);
			return visibleRules.length > 0;
		},
		isVisibleWinnerRoundBets() {
			// Todas las apuestas son visibles y no editables
			let visibleRules = this.winnerRoundBets.filter(x => x.allow_show_bets && !x.allow_edit_bets && x.team_id != null);
			return visibleRules.length > 0;
		},
		isVisibleScorerRoundBets() {
			// Todas las apuestas son visibles y no editables
			let visibleRules = this.scorerPersonBets.filter(x => x.allow_show_bets && !x.allow_edit_bets && x.person_id != null);
			return visibleRules.length > 0;
		}
	},
	methods: {
		update() {
			// TODO Update league table
			
			ApiService.getLastBets(this.leagueId, 
				(response) => {
					this.lastBets = response.data;
				},
				(error) => {
					this.lastBets = [];
		    	},
				() => {
					// Lanzar evento de actualización a los hijos
				}
			);
		},
		updateChart() {
			this.winnerRoundBets = [];
			ApiService.getWinnerRound(this.competitionId, 
					(response) => {
						let winnerRound = response.data;
						if(winnerRound != null) {
							ApiService.getRoundBetsRound(this.leagueId, winnerRound.id,
								(response) => {
									this.winnerRoundBets = response.data;
								},
								(error) => {
									this.winnerRoundBets = [];
						    	},
								() => {
									this.fillWinnersChart();
								}
							);
						}						
					},
					(error) => {
			    	},
					() => {}
				);

			this.nextRound = null;
			this.nextRoundBets = [];
			ApiService.getNextRound(this.competitionId, 
					(response) => {
						this.nextRound = response.data;
						if(this.nextRound != null) {
							ApiService.getRoundBetsRound(this.leagueId, this.nextRound.id,
									(response) => {
										this.nextRoundBets = response.data;
									},
									(error) => {
										this.nextRoundBets = [];
							    	},
									() => {
										this.fillNextRoundChart();
									}
								);
						}						
					},
					(error) => {
						this.nextRound = null;
			    	},
					() => {}
				);

			this.scorerPersonBets = [];
			ApiService.getScorerLeagueRule( 
					(response) => {
						let scorerLeagueRule = response.data;
						if(scorerLeagueRule != null) {
							ApiService.getPersonBetsLeagueRule(this.leagueId, scorerLeagueRule.id,
								(response) => {
									this.scorerPersonBets = response.data;
								},
								(error) => {
									this.scorerPersonBets = [];
						    	},
								() => {
									this.fillScorersChart();
								}
							);
						}						
					},
					(error) => {
			    	},
					() => {}
				);


		},
		fillWinnersChart() {
			const elementId = "winnersChart" + this.leagueId;
			clearChart(elementId);

			const counts = {};
			for (const roundBet of this.winnerRoundBets) {
				if(roundBet.team) {
					let key = roundBet.team.team_name;
					counts[key] = counts[key] ? counts[key] + 1 : 1;
				}
			}

			let labels = [];
			let data = [];
			for (var key in counts) {
				labels.push(key);
				data.push(counts[key]);
			}

			if(labels.length > 0) {
				let datasets = [{
						    label: I18n.t('number_of_people'),
						    data: data,
						    borderWidth: 1
						}];
				let options = {
						plugins: {
					    	legend: {
				                display: true,
				                labels: {
				                    color: 'rgb(255, 255, 255)'
				                }
					    	}
						}
				    };

				createChart(elementId, 'pie', labels, datasets, options);
			}
		},
		fillNextRoundChart() {
			const elementId = "nextRoundChart" + this.leagueId;
			clearChart(elementId);
			
			const numberOfUsers = this.nextRoundBets.map(x => x.user_id).filter((x, idx, arr) => arr.indexOf(x) === idx).length;
			
			const counts = {};
			for (const roundBet of this.nextRoundBets) {
				if(roundBet.team) {
					let key = roundBet.team.team_name;
					counts[key] = counts[key] ? counts[key] + 1 : 1;
				}
			}
			
			let labels = [];
			let data = [];

			var keys = Object.keys(counts);

			keys = keys.sort((a,b) => counts[b] - counts[a] || a.localeCompare(b));

			for (var index in keys) {
				var key = keys[index];
				labels.push(key);
				data.push(counts[key] / numberOfUsers);
			}
			
			if(labels.length > 0) {
				let datasets = [{
				    label: I18n.t('number_of_people'),
				    data: data,
				    borderWidth: 1
				}];
				let options = {
	 				maintainAspectRatio: false,
	 				indexAxis: 'y',
					plugins: {
				    	legend: {
			                display: true,
			                labels: {
			                    color: 'rgb(255, 255, 255)'
			                }
				    	}
					},
					scales: {
						x: {
							ticks: {
								color: 'rgb(255, 255, 255)',
								format: {
				                    style: 'percent'
				                }
							}
						},
						y: {
							ticks: {
								display: true,
								color: 'rgb(255, 255, 255)',
								/*
								font: {
									size: 10,
								}*/
							}
						}
					}
			    };
				createChart(elementId, 'bar', labels, datasets, options);
			}
		},
		fillScorersChart() {
			const elementId = "scorersChart" + this.leagueId;
			clearChart(elementId);

			const counts = {};
			for (const personBet of this.scorerPersonBets) {
				if(personBet.person) {
					let key = personBet.person.name;
					counts[key] = counts[key] ? counts[key] + 1 : 1;
				}
			}

			let labels = [];
			let data = [];
			for (var key in counts) {
				labels.push(key);
				data.push(counts[key]);
			}

			if(labels.length > 0) {
				let datasets = [{
						    label: I18n.t('number_of_people'),
						    data: data,
						    borderWidth: 1
						}];
				let options = {
						plugins: {
					    	legend: {
				                display: true,
				                labels: {
				                    color: 'rgb(255, 255, 255)'
				                }
					    	}
						}
				    };

				createChart(elementId, 'pie', labels, datasets, options);
			}
		}
	},
	components: {
		'bet' : Bet,
		'users-league-table' : UsersLeagueTable
	}
}
	
</script>

<style scoped>

@import "../../assets/stylesheets/league_summary.css"

</style>
