<script setup>
	import ModalWindow from "./modal_window.vue";
	import LeagueTable from "../packs/modules/LeagueTable";
	import ApiService from "../packs/modules/ApiService";
	var I18n = window.I18n;
</script>
<template>
	<div :class="{'table-container': true, 'compact': compact}">

		<div class="w-100 ta-center" v-if="!compact && hasDeparments" style="display: grid;grid-template-columns: 1fr 1fr;">
			<button @click="setUseDepartmentLeagueTable(false)" :class="{'button-change-league-table-left': true, 'active': !useDepartmentLeagueTable}">
				<span >Individual</span>
			</button>
			<button @click="setUseDepartmentLeagueTable(true)" :class="{'button-change-league-table-right': true, 'active': useDepartmentLeagueTable}">
				<span >Departamentos</span>
			</button>
		</div>

		<div :class="{'league-table-header table-header': true, 'compact' : compact}">
			<span class="league-table-column" @click="sortByPos()">#</span>
			<span class="league-table-user" @click="sortByName()">
				<span>{{ I18n.t('name') }}</span>
			</span>
			<span class="league-table-column" @click="sortByMatchday()" v-if="!compact">
				{{ I18n.t('matchday') }}
			</span>
			<span class="league-table-column optional" @click="sortByResults()" v-if="!compact">
				{{ I18n.t('results') }}
			</span>
			<span class="league-table-column optional" @click="sortByExtra()" v-if="!compact">
				{{ I18n.t('extra_points') }}
			</span>
			<span class="league-table-column" @click="sortByTotal()">
				{{ I18n.t('total') }}
			</span>
			<span class="league-table-column" v-if="!compact && !useDepartmentLeagueTable"></span>
		</div>
		<transition-group name="flip-list" tag="div" v-if="rowsToShow.length > 0">
	        <div v-for="score in rowsToShow" :key="leagueTable.leagueId + '_' + score.user_id" :class="{'league-table-row table-row': true, 'compact': compact, 'current-user': isCurrentUserRow(score)}">
	            <span class="league-table-column">{{ score.position }}</span>
	            <span class="league-table-user">
	            	<div :class="{'placeholder placeholder-glow' : loading}">
	            		{{ score.user_name }}
            		</div>
					<div v-if="score.department" class="league-table-user-department-sub">
						{{ score.department }}
					</div>
            	</span>
	    		<span class="league-table-column" v-if="!compact">
	            	<span :class="{'placeholder placeholder-glow' : loading, 'current-user-points-alt' : score.user_id == userId}">{{ matchday && score.points_by_matchday && score.points_by_matchday[matchday] ? Math.round(10 * score.points_by_matchday[matchday])/10 : Math.round(10 * score.points_last_matchday)/10 }}</span>
	    		</span>
	    		<span class="league-table-column optional" v-if="!compact">
	            	<span :class="{'placeholder placeholder-glow' : loading, 'current-user-points-alt' : score.user_id == userId}">{{ Math.round(10 * score.points_league)/10 }}</span>
	    		</span>
	    		<span class="league-table-column optional" v-if="!compact">
	            	<span :class="{'placeholder placeholder-glow' : loading, 'current-user-points-alt' : score.user_id == userId}">{{ Math.round(10 * (score.points_rounds + score.points_people))/10 }}</span>
	    		</span>
	    		<span class="league-table-column">
	            	<span :class="{'puntos league-table-total porra-tooltip': true, 'placeholder placeholder-glow' : loading, 'current-user-alt' : score.user_id == userId}">
	            		{{ Math.round(10 * score.points)/10 }}
						<span class="porra-tooltiptext right">
							<div class="points-detail">
								<span>{{ I18n.t('matchday') }}</span>
								<span>{{ matchday && score.points_by_matchday && score.points_by_matchday[matchday] ? Math.round(10 * score.points_by_matchday[matchday])/10 : Math.round(10 * score.points_last_matchday)/10 }}</span>
							</div>
							<div class="points-detail">
								<span>{{ I18n.t('results') }}</span>
								<span>{{ Math.round(10 * score.points_league)/10 }}</span>
							</div>
							<div class="points-detail">
								<span>{{ I18n.t('extra_points') }}</span>
								<span>{{ Math.round(10 * (score.points_rounds + score.points_people))/10 }} </span>
							</div>
						</span>
            		</span>
	    		</span>
	        	<span class="league-table-column" v-if="!compact">
					<span v-if="loading" class="link-fa link-shadow far fa-edit placeholder placeholder-glow"></span>
					<span v-else>
						<span class="fa-stack pointer" v-if="useDepartmentLeagueTable" @click="showModal(score.user_name)">
							<span class="fa-solid fa-users"></span>
							<span class="number-stack info-number-stack">{{ score.people }}</span>
						</span>
  						<a :href="'/' + I18n.locale + '/result_bets/new?league_id=' + leagueTable.leagueId  + '&user_id=' + score.user_id + '&matchday=' + matchday"
							:title="I18n.t('show_bets')" v-else>
							<span class="link-fa link-shadow far fa-edit"></span>
						</a>
					</span>
					<modal-window :ref="'modalUsersDepartment' + score.user_name" v-if="useDepartmentLeagueTable">
						<template v-slot:header>
							{{ score.user_name }}
						</template>
						<template v-slot:body>
							<div class="table-header department-row">
								<span class="">#</span>
								<span class="ta-left">{{ I18n.t('name') }}</span>
								<span>{{ I18n.t('points') }}</span>
							</div>
							<div v-for="row in usersLeagueTable.scoreRows.filter((x) => x.department == score.user_name)" 
								:key="row.user_name" class="users-department department-row">
								<span>{{ row.position }}</span>
								<span class="ta-left">{{ row.user_name }}</span>
								<span class="flex f-content-center">
									<span class="puntos">{{ row.points }}</span>
								</span>
							</div>
						</template>
					</modal-window>
	        	</span>
	        </div>
		</transition-group>
		<div class="link pad-5" @click="expandAll()" v-if="!compact && currentMaxRowsToShow < leagueTable.scoreRows.length">{{I18n.t('expand_all')}}</div>
	</div>
</template>

<script>
	export default {
		expose: ['update', 'rowsToShow'],
		props: {
		  	//leagueTable: LeagueTable,
		  	leagueId: Number,
		  	matchday: Number,
		  	userId: Number,
		  	maxRowsToShow: {
            	type: Number,
            	default: 10
        	},
		  	compact: {
            	type: Boolean,
            	default: false
        	}
		},
		data() {
			return {
				usersLeagueTable: new LeagueTable(this.leagueId, []),
				departmentsLeagueTable: new LeagueTable(this.leagueId, []),
				sortField: null,
				sortAscending: true,
				currentMaxRowsToShow: this.maxRowsToShow,
				useDepartmentLeagueTable: false
			}
		},
		computed: {
			leagueTable() {
				return this.useDepartmentLeagueTable ? this.departmentsLeagueTable : this.usersLeagueTable;
			},
			loading: function() {
				return this.leagueTable.scoreRows.length == 0;
			},
			rowsToShow: function() {
				var userInCurrentRows = this.leagueTable.scoreRows.filter((n, i) => i < this.currentMaxRowsToShow).filter((n) => this.isCurrentUserRow(n)).length > 0;
				var rows = this.leagueTable.scoreRows.filter((n, i) => i < (userInCurrentRows ? this.currentMaxRowsToShow : this.currentMaxRowsToShow - 1) || this.isCurrentUserRow(n));
				
				if(rows.length == 0 || this.loading) {
					rows = [];
					for(var i = 0; i < this.currentMaxRowsToShow; i++) {
						rows.push(
						{
							user_id: -(i + 1),
							position: i + 1,
							user_name: "...",
							points_by_matchday: {},
							points_league: " ",
							points_rounds: " ",
							points: " "
						});
					};
				}
				return rows;
			},
			sortingSign() {
				return this.sortAscending ? 1 : -1;
			},
			hasDeparments() {
				return this.usersLeagueTable.scoreRows.filter(a => a.department != null).length > 1;
			},
			userDepartment() {
				var rowsUser = this.usersLeagueTable.scoreRows.filter(a => a.user_id == this.userId);
				if(rowsUser.length == 1) {
					return rowsUser[0].department;
				}
				return null;
			}
		},
		methods: {
			expandAll: function() {
				this.currentMaxRowsToShow = this.leagueTable.scoreRows.length;
			},
			isCurrentUserRow(score) {
				return (!this.useDepartmentLeagueTable && score.user_id == this.userId) || (this.useDepartmentLeagueTable && score.user_name == this.userDepartment)
			},
			updateSort(field) {
				if(this.sortField == field) {
					this.sortAscending = !this.sortAscending;
				} else {
					this.sortField = field;
					this.sortAscending = true;
				}
			},
			sortByPos() {
				this.updateSort('pos');
				this.leagueTable.scoreRows.sort((a, b) => this.sortingSign * (a.position - b.position));
			},
			sortByName() {
				this.updateSort('name');
				this.leagueTable.scoreRows.sort((a, b) => this.sortingSign * (a.user_name.localeCompare(b.user_name)));
			},
			sortByMatchday() {
				this.updateSort('matchday');
				this.leagueTable.scoreRows.sort((a, b) => {
					var pointsA = this.matchday && a.points_by_matchday && a.points_by_matchday[this.matchday] ? a.points_by_matchday[this.matchday] : a.points_last_matchday;
					var pointsB = this.matchday && b.points_by_matchday && b.points_by_matchday[this.matchday] ? b.points_by_matchday[this.matchday] : b.points_last_matchday
					return this.sortingSign * (pointsA - pointsB);
				});
			},
			sortByResults() {
				this.updateSort('results');
				this.leagueTable.scoreRows.sort((a, b) => this.sortingSign * (a.points_league - b.points_league));
			},
			sortByExtra() {
				this.updateSort('extra');
				this.leagueTable.scoreRows.sort((a, b) => this.sortingSign * (a.points_rounds - b.points_rounds));
			},
			sortByTotal() {
				this.updateSort('total');
				this.leagueTable.scoreRows.sort((a, b) => this.sortingSign * (a.points - b.points));
			},
			update() {
				ApiService.getUsersLeagueTable(this.leagueId, 
						(response) => {

						    this.usersLeagueTable.scoreRows = response.data.usersLeagueTable;
						    this.departmentsLeagueTable.scoreRows = response.data.departmentsLeagueTable;
						    // TODO Volver a aplicar el orden activo
						},
						(error) => {
							this.usersLeagueTable.scoreRows = [];
							this.departmentsLeagueTable.scoreRows = [];
						},
						() => {
							//this.$emit('update-end');
						}
					);
			},
			setUseDepartmentLeagueTable(newValue) {
				if(newValue != this.useDepartmentLeagueTable) {
					this.useDepartmentLeagueTable = !this.useDepartmentLeagueTable;
					this.currentMaxRowsToShow = this.maxRowsToShow;
				}
			},
			showModal(department) {
				this.$refs['modalUsersDepartment' + department][0].show();
			}
		},
		components: {
			'modal-window': ModalWindow
		},
		mounted() {
			this.update();
		}
}
</script>

<style scoped>
	@import "../../assets/stylesheets/users_league_table.css"
</style>
