<script setup>
	import {formatDate, formatDayOfWeek, formatDayOfMonth} from '../packs/formats';
	import Match from "./match.vue";
	var I18n = window.I18n;
</script>
<template>
	<div class="carousel-container b-round">
		<span class="prev" @click="carouselLeft" :disabled="loading"><span class="fas fa-chevron-left"></span></span>
		<!--
	    <button class="carousel-porra-button left fa fa-chevron-circle-left" @click="carouselLeft" :disabled="loading"></button>
	    -->
		<div class="carousel-porra" id="carousel">
			<div v-for="day in days" :key="day" class="matches-day-block">
				<div class="matches-day-padding" disabled>
					<div v-if="loading">
						<!-- Empty space while loading -->
		            	<div class="">
	            		</div>
					</div>
					<div v-else>
						<div>
							{{ formatDayOfWeek(I18n, matchesByDay.get(day)[0].date)}}
						</div>
						<div>
							{{ formatDayOfMonth(I18n, matchesByDay.get(day)[0].date)}}
						</div>
					</div>
				</div>
				<div class="matches-list">
					<template v-for="matchIt in matchesByDay.get(day)" :key="matchIt.id" >
						<a :id="'match-' + matchIt.id" class="no-deco match-item"
								:href="'/' + I18n.locale + '/matches/' + matchIt.id + '/show_bets' + (leagueId != null ? '?league_id=' + leagueId : '')"
								:class="{'porra-active': isActive(matchIt.id), 'no-deco match-item' : true}"
								v-if="matchIt.id != null"> 
							<match 
								:match="matchIt"
								:loading="loading"
								>
							</match>
						</a>
						<span :class="{'porra-active': isActive(matchIt.id), 'no-deco match-item' : true}" v-else>
							<match 
								:match="matchIt"
								:loading="loading"
								>
							</match>
						</span>
					</template>
				</div>
			</div>
		</div>
		<span class="next" @click="carouselRight" :disabled="loading"><span class="fas fa-chevron-right"></span></span>
		<!--
	    <button class="carousel-porra-button right fa fa-chevron-circle-right" @click="carouselRight" :disabled="loading"></button>
	    -->
	</div>
</template>

<script>

	export default {
		expose: ['scrollToMatch'],
		props: {
	  		matches: Array,
	  		loading: Boolean,
	  		isActive: Function,
		  	leagueId: {
            	type: Number,
            	default: null
        	}
	  	},
	  	components: {
		    "match": Match
	  	},
	  	computed: {
		  	days: function() {
		  		var days = new Set(this.matches.map(match => formatDate(I18n, match.date)));

				return days;
		  	},
		  	matchesByDay: function() {
				var matchesByDay = new Map([]);
				this.days.forEach(day => matchesByDay.set(day, this.matches.filter(m => formatDate(I18n, m.date) == day)));
				return matchesByDay;
		  	}
		  },
		  methods: {
		  	scrollToMatch: function(id) {
		  		var el = document.getElementById('match-' + id);
		  		if(el) {
					el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		  		}
		  	},
			carouselLeft: function(event) {
				var carousel = document.getElementById("carousel");
				carousel.scrollTo(
					{
					  left: Math.max(0, carousel.scrollLeft - carousel.offsetWidth),
					  behavior: 'smooth'
					});
			},
			carouselRight: function(event) {
				var carousel = document.getElementById("carousel");
				carousel.scrollTo(
					{
						left: Math.min(carousel.scrollWidth - carousel.offsetWidth, carousel.scrollLeft + carousel.offsetWidth),
						behavior: 'smooth'
					});
			}
		 },
		 mounted() {
		 	const thisApp = this;
		 	this.matches.forEach(function(match) {
		 		if(thisApp.isActive(match.id)) {
			  		thisApp.scrollToMatch(match.id);
				}		 		
		 	});
		 }
	};
</script>

<style scoped>
	@import "../../assets/stylesheets/match_carousel.css"
</style>
