export function formatDate(I18n, date) {
	return date ? I18n.strftime(new Date(date), "%A, %d %B") : "";
};

export function formatTime(I18n, date) {
	return date ? I18n.strftime(new Date(date), "%H:%M") : "";
};

export function formatDayOfWeek(I18n, date) {
	return date ? I18n.strftime(new Date(date), "%A") : "";
};

export function formatDayOfMonth(I18n, date) {
	return date ? I18n.strftime(new Date(date), "%d %b") : "";
};

export function formatDateTime(I18n, date) {
	return date ? I18n.strftime(new Date(date), "%d %b\t%H:%M") : "";
};



