import axios from 'axios';
import { getKey, existsInLocal, loadJSONFromLocal, saveJSONToLocal } from '../functions';

const ACTIVATE_CACHE = true;

var I18n = window.I18n;
var authenticityToken = window.authenticityToken;

const TTL_1_MIN = 1 * 60 * 1000;
const TTL_30_MIN = 30 * 60 * 1000;

export default class ApiService {

	constructor() {
	}
	
	static getCompetition(competitionId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + "/api/v1/competition/" + competitionId;
		var key = getKey('competition', competitionId);

		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
  	}

  	static getActiveCompetitions(onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + "/api/v1/competition/index";
		var key = 'active_competitions';

		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}
	
	static getCompetitionLeagueRules(competitionId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/competition/league_rules/' + competitionId;
		var key = getKey('league_rules_competition_type', competitionId);

		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}
	
	static getGroupClassification(groupId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/competition/classification/' + groupId;
		ApiService.get(url, onResponse, onError, onFinally);
	}

	static getLeague(leagueId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + "/api/v1/leagues/" + leagueId;
		var key = getKey('league', leagueId);

		ApiService.get(url, onResponse, onError, onFinally, key, TTL_30_MIN);
		
	}

	static getUserLeagues(userId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + "/api/v1/leagues/index";
	  	if(userId != null) {
			  url = url + "?user_id=" + userId;
		}
		ApiService.get(url, onResponse, onError, onFinally);
	}

	static getUserLeaguesCompetition(competitionId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + "/api/v1/leagues/index?competition_id=" + competitionId;
		ApiService.get(url, onResponse, onError, onFinally);
	}

	static getUsersLeagueTable(leagueId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + "/api/v1/leagues/users_league_table/" + leagueId;
	  	var key = getKey('users_league_table', leagueId);
		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_1_MIN);
	}

	static getLeagueLeagueRules(leagueId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + "/api/v1/leagues/league_rules/" + leagueId;
		var key = getKey('league_rules_league', leagueId);

	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}
	
	static getLeaguesUsers(leagueId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + "/api/v1/leagues/leagues_users/" + leagueId;

		ApiService.get(url, onResponse, onError, onFinally);
	}

	static getLastBets(leagueId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/result_bets/last_bets/' + leagueId;
		ApiService.get(url, onResponse, onError, onFinally);
	}

  	static getRoundBetsRound(leagueId, roundId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/round_bets/round_bets/' + leagueId + "/" + roundId;
		ApiService.get(url, onResponse, onError, onFinally);
	}
	
	static getWinnerRound(competitionId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/competition/winner_round/' + competitionId;
		var key = getKey('winner_round', competitionId);
	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}
  
  	static getScorerLeagueRule(onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/league_rules/scorer';
	  	var key = getKey('scorer_league_rule');
		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}
	
	static getNextRound(competitionId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/competition/next_round/' + competitionId;
	  	var key = getKey('next_round', competitionId);
		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}
	
	static getMatch(matchId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/matches/' + matchId;
	  	var key = getKey('match', matchId);
		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_1_MIN);
	}

	static nextMatches(onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/matches/next_matches';
	  	var key = 'next_matches';
		// ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_1_MIN);
		ApiService.get(url, onResponse, onError, onFinally);
	}

  	static getMatches(competitionId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/matches/index/' + competitionId;
	  	var key = getKey('matches', competitionId);
		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_1_MIN);
  	}

  	static getMatchesByMatchday(competitionId, matchday, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/matches/index/' + competitionId + '?matchday=' + matchday;
	  	var key = getKey('matches_matchday_' + matchday, competitionId);
		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_1_MIN);
  	}

  	static getMinMaxMatchday(competitionId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/matches/min_max_matchday/' + competitionId;
	  	var key = getKey('min_max_matchday', competitionId);
		ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
  	}

  	static getAvailableTeamIds(competitionId, roundId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/competition/available_team_ids/' + competitionId + '/' + roundId;
		ApiService.get(url, onResponse, onError, onFinally);
	}
	
	static getRounds(competitionId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/rounds/index/' + competitionId;
		var key = getKey('rounds_competition', competitionId);

	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}

	static getRound(roundId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/rounds/' + roundId;
		var key = getKey('round', roundId);

	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}

	static getScorers(competitionId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/competition/scorers/' + competitionId;
		var key = getKey('scorers_competition', competitionId);

	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_1_MIN);
	}

	static getResultBets(leagueId, userId, matchday, mode, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/result_bets/index/' + leagueId + '?user_id=' + userId + '&matchday=' + matchday + '&mode=' + mode;
		ApiService.get(url, onResponse, onError, onFinally);
	}
	
	static getRoundBets(leagueId, userId, matchday, mode, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/round_bets/index/' + leagueId + '?user_id=' + userId + '&matchday=' + matchday + '&mode=' + mode;
		ApiService.get(url, onResponse, onError, onFinally);
	}
  
	static getPersonBets(leagueId, userId, mode, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/person_bets/index/' + leagueId + '?user_id=' + userId + '&mode=' + mode;
		ApiService.get(url, onResponse, onError, onFinally);
	}

	static getPersonBetsLeagueRule(leagueId, leagueRuleId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/person_bets/index_league_rule/' + leagueId + '/' + leagueRuleId;
		ApiService.get(url, onResponse, onError, onFinally);
	}

	static getMatchBets(leagueId, matchId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/result_bets/index/' + leagueId + '?match_id=' + matchId;
		ApiService.get(url, onResponse, onError, onFinally);
	}
	
  	static getMatchTeamsNextRoundByUser(leagueId, matchId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/round_bets/teams_next_round_by_user/' + leagueId + '/' + matchId;
		ApiService.get(url, onResponse, onError, onFinally);
	}
	
  	static getElegibleTeamIds(leagueId, userId, roundId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/round_bets/elegible_team_ids/' + leagueId + '/' + userId + '/' + roundId;
		ApiService.get(url, onResponse, onError, onFinally);
	}
	
	static getTeams(competitionId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/teams/index/' + competitionId;
		var key = getKey('teams_competition', competitionId);

	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}

	static getPeopleTeam(competitionId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/people/index/' + competitionId;
		var key = getKey('players_competition', competitionId);

	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}

	static getGroups(competitionId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/groups/index/' + competitionId;
		var key = getKey('groups_competition', competitionId);

	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}

	static getGroup(groupId, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/groups/' + groupId;
		var key = getKey('group', groupId);

	  ApiService.getWithCache(url, onResponse, onError, onFinally, key, TTL_30_MIN);
	}

  	static getAlerts(onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/alerts/index';
		ApiService.get(url, onResponse, onError, onFinally);
  	}

	static searchLeague(text, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/leagues/search';
	  	ApiService.post(url, {
			text: text,
			authenticity_token: authenticityToken
		}, onResponse, onError, onFinally);
	}
	
	static updateResultBet(betId, localGoals, visitingGoals, mode, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/result_bets/update_result_bet';
		ApiService.post(url, {
		  	result_bet_id: betId,
		  	local_goals: localGoals,
		  	visiting_goals: visitingGoals,
		  	mode: mode,
		  	authenticity_token: authenticityToken
			}, onResponse, onError, onFinally);
	}

	static updateRoundBet(isSelected, leagueId, leagueRuleId, teamId, userId, mode, onResponse, onError, onFinally) {
		var url = '/' + I18n.locale + '/api/v1/round_bets/' + ((isSelected) ? 'clear_round_bet' : 'fill_round_bet');
		ApiService.post(url,{
			league_id: leagueId,
			league_rule_id: leagueRuleId,
			team_id: teamId,
			user_id: userId,
		  	mode: mode,
		  	authenticity_token: authenticityToken
		}, onResponse, onError, onFinally);
    }
    
	static updatePersonBet(isSelected, leagueId, leagueRuleId, personId, userId, mode, onResponse, onError, onFinally) {
	    var url = '/' + I18n.locale + '/api/v1/person_bets/' + ((isSelected) ? 'clear_person_bet' : 'fill_person_bet');

    	ApiService.post(url,{
	    	league_id: leagueId,
			league_rule_id: leagueRuleId,
			person_id: personId,
			user_id: userId,
			mode: mode,
			authenticity_token: authenticityToken
		}, onResponse, onError, onFinally);
	}

    static resetBets(leagueId, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/result_bets/reset_bets';
		ApiService.post(url, {
		  	league_id: leagueId,
		  	authenticity_token: authenticityToken
		}, onResponse, onError, onFinally);
	}

	static copyBets(leagueId, leagueIdToCopy, onResponse, onError, onFinally) {
	  	var url = '/' + I18n.locale + '/api/v1/result_bets/copy_bets';
		ApiService.post(url, {
		  	league_id: leagueId,
			league_id_to_copy: leagueIdToCopy,
		  	authenticity_token: authenticityToken
		}, onResponse, onError, onFinally);
	}

	static suscribe(subscriptionJSON, onResponse, onError, onFinally) {
		ApiService.post('/api/v1/subscribe', {
			subscription: subscriptionJSON,
			authenticity_token: authenticityToken
		}, onResponse, onError, onFinally);
	}

	static unsuscribe(subscriptionJSON, onResponse, onError, onFinally) {
		ApiService.post('/api/v1/unsubscribe', {
			subscription: subscriptionJSON,
			authenticity_token: authenticityToken
		}, onResponse, onError, onFinally);
	}
	
	static updateDepartment(leagueId, userId, department, onResponse, onError, onFinally) {
		ApiService.post('/api/v1/leagues/update_department', {
			league_id: leagueId,
			user_id: userId,
			department: department,
			authenticity_token: authenticityToken
		}, onResponse, onError, onFinally);
	}
	
	static getWithCache(url, onResponse, onError, onFinally, key, ttl) {
		if(ACTIVATE_CACHE) {
			if(existsInLocal(key)) {
				if(onResponse) {
					onResponse(loadJSONFromLocal(key));
				}
				if(onFinally) {
					onFinally();
				}
			}
			else {
				var proxyOnResponse = (response) => {
					saveJSONToLocal(key, response.data, ttl);
					if(onResponse) {
						onResponse(response);
					}
				};
				ApiService.get(url, proxyOnResponse, onError, onFinally);
			}		
		} else {
			ApiService.get(url, onResponse, onError, onFinally);
		}
	}
	
	static get(url, onResponse, onError, onFinally) {
	  	axios.get(url)
		.then(response => {
			if(onResponse) {
	  			onResponse(response);
	  		}
	  	})
		.catch(error => {
			
			var statusCode = error.response ? error.response.status : -1;
			
	  		if (statusCode == 401) { // :forbidden
		  		// Si no está autenticado, se envía a la pantalla de inicio (para nuevo login)
		  		document.location.href="/";
	  		} else if (statusCode == 400) { // :bad_request
		  		// Si es un error de parámetros erróneos se envía a la página de rechazo
		  		document.location.href="/422.html";
	  		} else if (statusCode == 500) { // :internal_server_error
		  		// Si es un error de guardado, se envía a la página de error del servidor
		  		document.location.href="/500.html";
	  		} else {
			  	console.log(error);
	  		}
			if(onError) {
	  			onError(error);
	  		}
		})
		.finally(() => {
			if(onFinally) {
				onFinally();
			}
	  	});
	}
	
	static post(url, params, onResponse, onError, onFinally) {
	  	axios.post(url, params)
		.then(response => {
			if(onResponse) {
	  			onResponse(response);
	  		}
		})
		.catch(error => {
			var statusCode = error.response ? error.response.status : -1;
			
	  		if (statusCode == 401) { // :forbidden
		  		// Si no está autenticado, se envía a la pantalla de inicio (para nuevo login)
		  		document.location.href="/";
	  		} else if (statusCode == 400) { // :bad_request
		  		// Si es un error de parámetros erróneos se envía a la página de rechazo
		  		document.location.href="/422";
	  		} else if (statusCode == 500) { // :internal_server_error
		  		// Si es un error de guardado, se envía a la página de error del servidor
		  		document.location.href="/500";
	  		} else {
			  	console.log(error);
	  		}
			if(onError) {
	  			onError(error);
	  		}
		})
		.finally(() => {
			if(onFinally) {
				onFinally();
			}
		});
	}

}
