<script setup>
	import Logo from "./logo.vue";
	import BetForm from "./bet_form.vue";
	import Points from "./points.vue";
	import ActionManager from "../packs/modules/ActionManager";
	import {formatDateTime} from "../packs/formats";
	
	var I18n = window.I18n;
</script>
<template>
	<div class="bet-container">

		<a :href="'/' + I18n.locale + '/matches/' + bet.match_id + '/show_bets?league_id=' + bet.league_id" :title="localTeamName + ' vs ' + visitingTeamName" class="no-deco bet-header-date b-light b-round">
			{{ formatDateTime(I18n, match.date) }}<span class="fas fa-external-link-alt"></span>
		</a>
		<span :title="localTeamName + ' vs ' + visitingTeamName" class="no-deco bet-header-result" v-if="match.started">
			<div class="bet-header-result-inline">
				<div :class="{'marcador-pizarra regular': true, 'no-empezado': !match.started, 'final': match.finished, 'pendiente': match.started && !match.finished}">
					{{ local_goals }}<small><sub>{{ match.local_goals_extra_time }}</sub></small>
				</div>
				<div :class="{'marcador-pizarra regular': true, 'no-empezado': !match.started, 'final': match.finished, 'pendiente': match.started && !match.finished}">
					{{ visiting_goals }}<small><sub>{{ match.visiting_goals_extra_time }}</sub></small>
				</div>
			</div>
			<span class="fas fa-angle-double-right"></span>
			<points
				v-bind:points="bet.points_info.points"
				v-bind:max-points="bet.points_info.max_points"
				v-bind:legends="bet.points_info.legend"
				legend-position="right"
				>
			</points>
		</span>
		<div class="bet-body">
			<span class="team-with-logo-inverse">
				<span class="">
					{{ localTeamName }}
				</span>
				<logo :team="match.local_team" v-bind:small="true"></logo>
			</span>
			<span class="bet-result">
				<bet-form
					v-bind:bet="bet"
					v-bind:mode="mode"
					v-bind:action-manager="actionManager">
				</bet-form>
			</span>
			<span class="team-with-logo">
				<logo :team="match.visiting_team" :small="true"></logo>
				<span>
					{{ visitingTeamName }}
				</span>
			</span>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'bet',
		props: {
			bet: Object,
			mode: String,
			actionManager: ActionManager
		},
		computed: {
			match: function() {
				return this.bet.match;
			},
	  		local_goals: function() {
	  			return this.match.local_goals != null ? this.match.local_goals : "-";
	  		},
	  		visiting_goals: function() {
	  			return this.match.visiting_goals != null ? this.match.visiting_goals : "-";
	  		},
			localTeamId: function() {
				return this.match.local_team_id != null ? this.match.local_team_id : '';
			},
			visitingTeamId: function() {
				return this.match.visiting_team_id != null ? this.match.visiting_team_id : '';
			},
			localTeamName: function() {
				return this.match.local_team != null ? this.match.local_team.team_name : '';
			},
			visitingTeamName: function() {
				return this.match.visiting_team != null ? this.match.visiting_team.team_name : '';
			}
		},
	    components: {
	        "logo": Logo,
	        "bet-form": BetForm,
	        "points": Points
    	}
	}
</script>

<style scoped>
	@import "../../assets/stylesheets/bet.css"
</style>
