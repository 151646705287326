<template>
	<transition-group name="flip-list" tag="div" :class="paginationClass" id="currentPages">
		<span key="init">
			<a rel="prev" :class="{'previous_page pointer': true, 'hidden' : page == minPage}" @click="setPage(page - 1)" tabindex="">
				<i class="fas fa-chevron-left"></i>
			</a>
			<span rel="prev" :class="{'previous_page': true, 'hidden' : page != minPage}">
				<i class="fas fa-chevron-left"></i>
			</span>
		</span>
		<span v-for="day in initialPages" :key="day">
			<a :class="{'pointer' : page != day, 'hidden': page == day}" @click="setPage(day)" tabindex="">{{ day }}</a>
			<em :class="{'current' : page == day, 'hidden': page != day}">{{ day }}</em>
		</span>
		<span :class="{'hidden': !hayGapPrevious}" key="first-gap">
			<span class="gap">-</span>
		</span>
		<span v-for="day in currentPages" :key="day">
			<a :class="{'pointer' : page != day, 'hidden': page == day}" @click="setPage(day)" tabindex="">{{ day }}</a>
			<em :class="{'current' : page == day, 'hidden': page != day}">{{ day }}</em>
		</span>
		<span :class="{'hidden': !hayGapNext}" key="last-gap">
			<span class="gap">-</span>
		</span>
		<span v-for="day in finalPages" :key="day">
			<a :class="{'pointer' : page != day, 'hidden': page == day}" @click="setPage(day)" tabindex="">{{ day }}</a>
			<em :class="{'current' : page == day, 'hidden': page != day}">{{ day }}</em>
		</span>
		<span key="end">
			<a rel="next" :class="{'next_page pointer': true, 'hidden' : page == maxPage}" @click="setPage(page + 1)" tabindex="">
				<i class="fas fa-chevron-right"></i>
			</a>
			<span rel="next" :class="{'next_page': true, 'hidden' : page != maxPage}">
				<i class="fas fa-chevron-right"></i>
			</span>
		</span>
	</transition-group>
</template>

<script>
	export default {
		name: 'pagination',
	  	data: function() {
	  		return {
	  			page: this.initialPage,
		  		windowWidth: window.innerWidth
	  		}
	  	},
	  	mounted() {
			window.addEventListener('resize', () => {
				this.windowWidth = window.innerWidth;
		   	});
	  	},
	  	props: {
		    initialPage: Number,
		    minPage: Number,
		    maxPage: Number,
		    paginationClass: {type: String, default: 'porra-pagination'}
	  	},
		computed: {
			isValid() {
				return this.minPage && this.maxPage;
			},
	  		daysInit: function() {
		  		return this.windowWidth > 480 ? 2 : 1;
		  	},
		  	daysEnd: function() {
		  		return this.windowWidth > 480 ? 2 : 1;
		  	},
		  	daysAround: function() {
		  		return this.windowWidth > 480 ? (this.windowWidth > 768 ? 3 : 1) : 0;
		  	},
			start: function() {
				return this.isValid ? 
						Math.max(this.minPage + this.daysInit, Math.min(this.page - this.daysAround, this.maxPage - (this.daysEnd - 1) - 2 * this.daysAround - 2))
						: 0;
			},
			end: function() {
				return this.isValid ? 
						Math.min(this.maxPage - this.daysEnd, Math.max(this.page + this.daysAround, this.minPage + (this.daysInit - 1) + 2 * this.daysAround + 2))
						: 0;
			},
		  	initialPages: function() {
		  		var pages = [];
		  		if(this.isValid) {
			  		for(var i = this.minPage; i <= Math.min(this.minPage + (this.daysInit - 1), this.maxPage); i++) {
			  			pages.push(i);
			  		}
		  		}
		  		return pages;
		  	},
		  	currentPages: function() {
		  		var pages = [];
		  		if(this.isValid) {
			  		for(var i = this.start; i <= this.end; i++) {
			  			pages.push(i);
			  		}
		  		}
		  		return pages;
		  	},
		  	finalPages: function() {
		  		var pages = [];
		  		if(this.isValid) {
			  		for(var i = Math.max(this.maxPage - (this.daysEnd - 1), this.minPage + this.daysInit); i <= this.maxPage; i++) {
			  			pages.push(i);
			  		}
		  		}
		  		return pages;
		  	},
		  	hayGapPrevious: function() {
		  		return this.isValid && this.minPage + this.daysInit < this.start;
		  	},
		  	hayGapNext: function() {
		  		return this.isValid && this.maxPage - this.daysEnd > this.end;
		  	}
		},
		methods: {
			setPage(newPage) {
				if(this.maxPage && this.minPage) {
					this.page = Math.min(this.maxPage, Math.max(this.minPage, newPage));
					this.$emit('page-change', newPage);
				}
			}
		},
		components: {
		}
	}
</script>

<style scoped>
</style>
