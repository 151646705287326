<template>
	<span :class="{
		 'points':true,
		 'badge bg-success': points > 0.66 * maxPoints,
		 'badge bg-info': points <= 0.66 * maxPoints && points > 0.33 * maxPoints,
		 'badge bg-primary': points <= 0.33 * maxPoints && points > 0,
		 'badge bg-zero': points <= 0
	 }">
	 	<span class="porra-tooltip">
			{{ points }}
			<span :class="tooltipClass">
				<template  v-for="(legend, index) in legends" :key="index"> 
					<div v-if="legend" :class="{'points-detail': true, 'alert-success' : legend.points == legend.max_points, 'alert-danger' : legend.points != legend.max_points}">
						<span>{{ legend.description }}</span>
						<span class="">{{ legend.points }} / {{ legend.max_points }}</span>
					</div>
				</template>
			</span>
		</span>
	</span>
</template>

<script>
	export default {
		name: 'points',
		data: function() {
			return {
		  	}
		},
		props: {
			points: Number,
			maxPoints: Number,
			legends: Array,
			legendPosition: String
		},
		computed: {
			tooltipClass: function() {
				return 'porra-tooltiptext ' + this.legendPosition;
			}
		},
	    components: {
    	}
	}
</script>

<style scoped>
	@import "../../assets/stylesheets/points.css"
</style>
