export function drawCircleWithColors(canvasId, x, y, radius, colors) {

    const canvas = document.getElementById(canvasId);
    const context = canvas.getContext("2d");

    context.lineWidth = 0;
    context.strokeStyle = '#000000';

    // var initialAngle = 3 * Math.PI / 4;
    var initialAngle = Math.PI;

    for(var i = 0; i < colors.length; i++) {
        var color = colors[i];
        context.beginPath();
        // Define a start Point
        context.moveTo(x, y);
        // Define an end Point
        context.arc(x, y, radius, i * 2 * Math.PI / colors.length + initialAngle, (i + 1) * 2 * Math.PI / colors.length + initialAngle, false);
        context.lineTo(x, y);
        //context.lineTo(x, y);
        context.fillStyle = color;
        context.fill();
        context.stroke();
    }

};

