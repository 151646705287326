<script setup>
</script>
<template>
	<div class="porra-modal animate__animated animate__slideInDown" v-if="showModal" @click="showModal = false">
		<div class="porra-modal-dialog" v-on:click.stop>
			<div class="porra-modal-content">
				<div :class="{'porra-well': true, 'porra-modal-warning': warning, 'porra-modal-danger': danger}">
					<h4 class="modal-title"><slot name="header"></slot></h4>
					<button type="button" class="btn-close" @click="showModal = false"></button>
				</div>
				<div class="porra-modal-body">
					<slot name="body"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		danger: {
			
		},
		warning: {
			
		}
  	},
 	data() {
	  	return {
	  		showModal: false
	  	}
	},
  	computed: {
  	},
  	methods: {
 		show() {
 			this.showModal = true;
 		},
 		hide() {
 			this.showModal = false;
 		}
  	}
};
</script>

<style scoped>
	@import "../../assets/stylesheets/modal_window.css"
</style>