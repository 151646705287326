<script setup>
	import ApiService from '../packs/modules/ApiService'
	import {formatTime} from '../packs/formats';
	import Logo from "./logo.vue";
	var I18n = window.I18n;
</script>
<template>
	<div class="match-component">
		<div class="match-date flex f-content-space-between" v-if="isValid">
			<span class="match-matchday" v-if="!competition || !competition.competition_type || competition.competition_type.is_league">{{ I18n.t('matchday_short') }} {{ match.matchday }}</span>
			<span class="match-matchday" v-else-if="group && group.name">{{ I18n.t('group') }} {{ group.name }}</span>
			<span class="match-matchday" v-else="">{{ round && round.name ? round.name : '' }}</span>
			<span>{{ formatTime(I18n, match.date) }}</span>
		</div>
		<div class="match-date placeholder placeholder-glow" v-else>
			{{ formatTime(I18n, match.date) }}
		</div>
		<div class="match-team-line">
			<logo :team="match.local_team" :small="true" class="match-team-logo"></logo>
			<span class="match-team-name">{{ localTeamAbbreviation }}</span>
			<span class="match-result">
				<div :class="{'marcador-pizarra regular': true,
				 'no-empezado': isValid && !match.started, 'pendiente' : isValid && match.started && !match.finished, 'final': isValid && match.finished,
				'placeholder placeholder-glow': !isValid}">
					<template v-if="loading">
						<i class="fa fa-spinner fa-pulse fa-fw"></i>
					</template>
					<template v-else>
						{{ localGoals }}<small><sub>{{ match.local_goals_extra_time }}</sub></small>
					</template>
				</div>
			</span>
		</div>
		<div class="match-team-line">
			<logo :team="match.visiting_team" :small="true" class="match-team-logo"></logo>
			<span class="match-team-name">{{ visitingTeamAbbreviation }}</span>
			<span class="match-result">
				<div :class="{'marcador-pizarra regular': true,
				 'no-empezado': isValid && !match.started, 'pendiente' : isValid && match.started && !match.finished, 'final': isValid && match.finished,
				'placeholder placeholder-glow': !isValid}">
					<template v-if="loading">
						<i class="fa fa-spinner fa-pulse fa-fw" v-if="loading"></i>
					</template>
					<template v-else>
						{{ visitingGoals }}<small><sub>{{ match.visiting_goals_extra_time }}</sub></small>
					</template>
				</div>
			</span>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'match',
		data() {
			return {
				competition: {},
				group: {},
				round: {}
			}
		},
		props: {
	  		match: Object,
	  		loading: {
	  			type: Boolean,
	        	default: false
	  		}
	  	},
	  	computed: {
	  		isValid() {
	  			return this.match.id != null;
	  		},
	  		localTeamAbbreviation: function() {
	  			return this.match.local_team != null ? this.match.local_team.abbreviation : "-";
	  		},
	  		visitingTeamAbbreviation: function() {
	  			return this.match.visiting_team != null ? this.match.visiting_team.abbreviation : "-";
	  		},
	  		localGoals: function() {
	  			return this.match.local_goals != null ? this.match.local_goals : "-";
	  		},
	  		visitingGoals: function() {
	  			return this.match.visiting_goals != null ? this.match.visiting_goals : "-";
			}
	  	},
	  	methods: {
			/*
	  		getUltraShortAbreviation: function(teamName) {
	  			if(!teamName) {
	  				return "";
	  			}
	  			var maxLength = 3;
	  			var words = teamName.split(' ');
	  			var abrev = "";
	  			for(var i = 0; i < words.length; i++) {
	  				var word = words[i];
	  				if(abrev.length < maxLength) {
	  					if(i == (words.length - 1)) {
	  						var j = 0;
	  						while(abrev.length < maxLength && j < word.length) {
				  				abrev += word[j++];
	  						}
	  					} else {
			  				abrev += word[0];
	  					}
	  				}
	  			}
	  			return abrev;
	  		}
			*/
	  	},
	  	components: {
		    "logo": Logo
	  	},
		mounted() {
			if(this.match.competition_id) {
				ApiService.getCompetition(this.match.competition_id,
					(response) => {
						this.competition = response.data;
					}, (error) => {}, () => {}
				);
			}
			if(this.match.group_id) {
				ApiService.getGroup(this.match.group_id,
					(response) => {
						this.group = response.data;
					}, (error) => {}, () => {}
				);
			}
			if(this.match.round_id) {
				ApiService.getRound(this.match.round_id,
					(response) => {
						this.round = response.data;
					}, (error) => {}, () => {}
				);
			}
		}
	};
</script>

<style scoped>
	@import "../../assets/stylesheets/match.css"
</style>